import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import homefirstslider from "../images/homefirstslider.svg";
import homesecondslider from "../images/homesecondslider.svg";
import homethirdslider from "../images/homethreeslider.svg";
import homefourslider from "../images/homefourslider.svg";

function HomeSlider() {
  const settings = {
    // dots: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings} className="d-flex ">
        <div className="container">
          <div className="home-slider-design  ">
            <div className="home-first-imgslider">
              <img src={homefirstslider} className="img-fluid" alt="ss"></img>
              <div className="sticky-top-txt">
                <h4>New</h4>
              </div>
            </div>
            <h2>Sweets</h2>
            <h3>Green Cauliflower</h3>
            <h5>₹39.85</h5>
            <div className="rating-product">
              <h6>(120 Reviews)</h6>
              <div className="d-flex-rate">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="home-slider-design ">
            <div className="home-first-imgslider">
              <img src={homesecondslider} className="img-fluid" alt="ss"></img>
              <div className="sticky-top-txt red-bg">
                <h4>Hot</h4>
              </div>
            </div>
            <h2>Sweets</h2>
            <h3>Green Cauliflower</h3>
            <h5>₹39.85</h5>
            <div className="rating-product">
              <h6>(120 Reviews)</h6>
              <div className="d-flex-rate">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="home-slider-design ">
            <div className="home-first-imgslider">
              <img src={homethirdslider} className="img-fluid" alt="ss"></img>
              <div className="sticky-top-txt">
                <h4>Hot</h4>
              </div>
            </div>
            <h2>Sweets</h2>
            <h3>Green Cauliflower</h3>
            <h5>₹39.85</h5>
            <div className="rating-product">
              <h6>(120 Reviews)</h6>
              <div className="d-flex-rate">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="home-slider-design ">
            <div className="home-first-imgslider">
              <img src={homefourslider} className="img-fluid" alt="ss"></img>
              <div className="sticky-top-txt">
                <h4>Hot</h4>
              </div>
            </div>
            <h2>Sweets</h2>
            <h3>Green Cauliflower</h3>
            <h5>₹39.85</h5>
            <div className="rating-product">
              <h6>(120 Reviews)</h6>
              <div className="d-flex-rate">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default HomeSlider;
