import React from 'react'
import Header from './Header'
import Footer from './Footer';
import locationicon from '../images/location-icon-contact.svg'
import callicon from '../images/call-icon-contact.svg'
import mailicon from '../images/email-icon-contact.svg'
import websiteicon from '../images/website-icon-contact.svg'
import { Link } from 'react-router-dom'

const MyOrder = () => {

    return (
        <div>
            <Header />
            <div className='aboutfirst mt-103'>
                <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>Order Detail</p>
            </div>
            <div className='container p-main-80'>
                <div className='order-details-page mb-3 '>
                    <div className='order-detail-text'>
                        <h2>Order Detail:</h2>
                        <h3>Your Order Will Deliver on  <span>15.08.2018</span></h3>
                        <Link to={'/MyOrder'}>VIEW Order</Link>
                    </div>
                    <hr />
                    <div className='order-detail-no'>
                        <h2>Order No: <span>#236545</span></h2>
                        <div className='row'>
                            <div className='col-lg-6 col-12'>
                                <div className='shipping-txt'>
                                    <h3>Shipping To:</h3>
                                    <p>Ellen Wheelock</p>
                                    <p>631 North Coral Court Kernersville, NC 27284</p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='shipping-txt'>
                                    <h3>Billing To:</h3>
                                    <p>Ellen Wheelock</p>
                                    <p>631 North Coral Court Kernersville, NC 27284</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='order-details-page p-0 mb-3 mt-2'>
                    <div className='order-detail-text spl-paddomg'>
                        <h2>Order Detail:</h2>
                        <h5>Your Order Canceled </h5>
                        <Link to={'/MyOrder'}>VIEW Order</Link>
                    </div>
                    <hr />
                    <div className='order-detail-no'>
                        <h2 >Order No: <span>#236545</span></h2>
                        <div className='row'>
                            <div className='col-lg-6 col-12'>
                                <div className='shipping-txt'>
                                    <h3>Shipping To:</h3>
                                    <p>Ellen Wheelock</p>
                                    <p>631 North Coral Court Kernersville, NC 27284</p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='shipping-txt'>
                                    <h3>Billing To:</h3>
                                    <p>Ellen Wheelock</p>
                                    <p>631 North Coral Court Kernersville, NC 27284</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='order-detail-red '>
                        <h6>Why Canceled Order ?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make</p>
                    </div>
                </div>

                <div className='order-details-page mb-3 mt-2'>
                    <div className='order-detail-text green-order'>
                        <h2>Order Detail:</h2>
                        <h3>Your Order Will Deliver on  <span>15.08.2018</span></h3>
                        <Link to={'/MyOrder'}>VIEW Order</Link>
                    </div>
                    <hr />
                    <div className='order-detail-no '>
                        <h2>Order No: <span>#236545</span></h2>
                        <div className='row'>
                            <div className='col-lg-6 col-12'>
                                <div className='shipping-txt'>
                                    <h3>Shipping To:</h3>
                                    <p>Ellen Wheelock</p>
                                    <p>631 North Coral Court Kernersville, NC 27284</p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='shipping-txt'>
                                    <h3>Billing To:</h3>
                                    <p>Ellen Wheelock</p>
                                    <p>631 North Coral Court Kernersville, NC 27284</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default MyOrder
