import React from 'react'
import Header from './Header'
import Footer from './Footer';
import paypal from '../images/paypal.svg'
import { Link } from 'react-router-dom'

const PaymentOption = () => {
    return (
        <div>
            <Header />
            <div className='aboutfirst mt-103'>
                <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>Place Order</p>
            </div>
            <div className='container p-main-80'>
                <div className='payment-link-option'>
                    <p>Returning customer? <Link to={'/'} >Click here to login </Link></p>
                    <p>Have a coupon? <Link to={'#'} >Click here to enter your code</Link></p>
                </div>
                <div className='row spl-mt-product'>
                    <div className='col-lg-8 col-md-6 col-12 '>
                        <div className='product-form'>
                            <h3 className='mt-0'>Billing details</h3>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 '>
                                <label for="firstname" class="form-label p-label">First Name <span>*</span></label>
                                <input type="text" class="form-control p-control" placeholder="First name" aria-label="First name" />
                            </div>
                            <div className='col-lg-6 col-md-12 '>
                                <label for="lastname" class="form-label p-label">Last Name <span>*</span></label>
                                <input type="text" class="form-control p-control" placeholder="First name" aria-label="Last name" />
                            </div>
                            <div className='col-12 '>
                                <label for="companyname" class="form-label p-label">Company Name <span>*</span></label>
                                <input type="text" class="form-control p-control" placeholder="Company Name" aria-label="Company name" />
                            </div>
                            <div className='col-12 '>
                                <label for="companyname" class="form-label p-label">Country  <span>*</span></label>
                                <select class="form-select p-control p-control-to" aria-label="Default select example">
                                    <option value="1">USA</option>
                                    <option value="2">INDIA</option>
                                    <option value="3">JAPAN</option>
                                </select>
                            </div>
                            <div className='col-12 '>
                                <label for="Address" class="form-label p-label">Address <span>*</span></label>
                                <input type="text" class="form-control p-control mb-3" placeholder="Street address" aria-label="Address" />
                                <input type="text" class="form-control p-control " placeholder="Apartment, suite, unit etc. (optional)" aria-label="Address" />
                            </div>
                            <div className='col-12 '>
                                <label for="Town/City" class="form-label p-label">Town/City <span>*</span></label>
                                <input type="text" class="form-control p-control " placeholder="Town/City" aria-label="Town/City " />
                            </div>
                            <div className='col-12 '>
                                <label for="County" class="form-label p-label">County  <span>*</span></label>
                                <input type="text" class="form-control p-control " placeholder="County" aria-label="County " />
                            </div>
                            <div className='col-12 '>
                                <label for="Postcode / Zip" class="form-label p-label">Postcode / Zip   <span>*</span></label>
                                <input type="number" class="form-control p-control " placeholder="Postcode / Zip" aria-label="Postcode / Zip " />
                            </div>
                            <div className='col-lg-6 col-md-12 '>
                                <label for="Phone " class="form-label p-label">Phone    <span>*</span></label>
                                <input type="number" class="form-control p-control " placeholder="Phone " aria-label="Phone  " />
                            </div>
                            <div className='col-lg-6 col-md-12 '>
                                <label for="exampleFormControlInput1 p-label" class="form-label">Email address<span>*</span></label>
                                <input type="email" class="form-control p-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                            </div>
                            <div className='col-6 '>
                                <div class="form-check p-check">
                                    <input class="form-check-input p-label" type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label" for="flexCheckDefault">Create an account?</label>
                                </div>
                            </div>
                            <div className='product-form'>
                                <h3>Additional information</h3>
                            </div>
                            <div className='col-12'>
                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label p-label">Order notes</label>
                                    <textarea class="form-control p-control" id="exampleFormControlTextarea1" rows="8" placeholder='Note about your order, eg. special notes fordelivery.'></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div className='prodcuct-sec-two'>
                            <h3>Your order</h3>
                            <div className='product-total pt-3'>
                                <h4>Product</h4>
                                <h4>Total</h4>
                            </div>
                            <hr />
                            <div className='product-total pt-3'>
                                <h4>Cherry * 2</h4>
                                <h4>36$</h4>
                            </div>
                            <hr />
                            <div className='product-total pt-3'>
                                <h4>Asparagus * 1</h4>
                                <h4>12$ </h4>
                            </div>
                            <hr />
                            <div className='product-total-to pt-3'>
                                <h3>Subtotal</h3>
                                <h4>48$</h4>
                            </div>
                            <hr />
                            <div className='product-total-to'>
                                <h5>Total</h5>
                                <h6>48$</h6>
                            </div>

                            <div className='pro-section-payment'>
                                <div className='p-15-20'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        <label class="form-check-label ff-label" for="flexRadioDefault2">Check Payments</label>
                                    </div>
                                </div>
                                <div className='p-15-20'>
                                    <p>Please send a check to Store Name, Store
                                        Street, Store Town, Store State / County, Store
                                        Postcode.</p>
                                </div>
                                <div className='p-15-20 what-is-paypal'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        <label class="form-check-label ff-label" for="flexRadioDefault2">Paypal</label>
                                    </div>
                                    <img src={paypal} alt='paypal' className='ms-3 mt-3 mb-3'></img>
                                    <p><Link to={'#'} className='ms-3'>What is paypal?</Link></p>
                                </div>
                            </div>
                            <div className='place-oreder'>
                                <Link to={'#'}>Place order</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PaymentOption
