import React from 'react'
import creat from '../images/create-account.svg'; // with import
import { Link } from 'react-router-dom';


const CreateAccount = () => {
  return (
    <div>
        <div className='bg-create'>
            <img src={creat}/>
        </div>
        <div className='container pb-5'>
            <div className='row spl-center top-form'>
                <div className='col-lg-8 col-md-8 col-12'>
                        <div className='white-bg'>
                            <div className='createtxt'>
                                <h2>Create Account</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
                            </div>
                            <div className='col-12 '>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label frn-label">Username <span>**</span></label>
                                    <input type="text" className="form-control frn-control" id="exampleFormControlInput1" placeholder="Enter Username..."/>
                                </div>
                            </div>
                            <div className='col-12 '>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label frn-label">Email address <span>**</span></label>
                                    <input type="email" className="form-control frn-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
                                </div>
                            </div>
                            <div className='col-12 '>
                                <div className="mb-3">
                                    <label for="exampleInputPassword1" className="form-label frn-label">Password <span>**</span></label>
                                    <input type="password" className="form-control frn-control" id="exampleInputPassword1" placeholder='Enter password'/>
                                </div>
                            </div>
                            <div className='col-12'>
                            <Link to={'#'} className='regi-btn'> Register now</Link>
                            </div>
                            <div className='col-12 mt-3 mb-3'>
                                <div className='or-txt'>
                                        <hr/> 
                                        <h3>or</h3>
                                        <hr/>
                                </div>
                            </div>
                            <div className='col-12'>
                                <Link to={"/"} className='regi-btn-to'>Login now</Link>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CreateAccount