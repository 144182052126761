import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Scrolltotop from './Scrolltotop';
import Home from './Components/Home';
import CreateAccount from './Components/CreateAccount';
import LoginPage from './Components/LoginPage';
import About from './Components/About';
import Shop from './Components/Shop';
import PaymentOption from './Components/PaymentOption';
import ContactUs from './Components/ContactUs';
import Wishlist from './Components/Wishlist';
import Cart from './Components/Cart';
import MyOrder from './Components/MyOrder';
import AboutItem from './Components/AboutItem';
import OrderDetail from './Components/OrderDetail';

function App() {
  return (
    <div className="App">
      <Router>
        <Scrolltotop />
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/CreateAccount' element={<CreateAccount />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Shop' element={<Shop />} />
          <Route path='/PaymentOption' element={<PaymentOption />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/Wishlist' element={<Wishlist />} />
          <Route path='/Cart' element={<Cart />} />
          <Route path='/MyOrder' element={<MyOrder />} />
          <Route path='/AboutItem' element={<AboutItem />} />
          <Route path='/OrderDetail' element={<OrderDetail />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
