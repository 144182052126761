import React from 'react'
import Header from './Header'
import Footer from './Footer';
import locationicon from '../images/location-icon-contact.svg'
import callicon from '../images/call-icon-contact.svg'
import mailicon from '../images/email-icon-contact.svg'
import websiteicon from '../images/website-icon-contact.svg'
import { Link } from 'react-router-dom'

const ContactUs = () => {
    return (
        <div>
            <Header />
            <div className='aboutfirst mt-103'>
                <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>Contact Us</p>
            </div>
            <div className='container p-main-80'>
                <div className='payment-link-option home-heading'>
                    <p className='mb-0'><Link to={'#'} >Get In Touch </Link></p>
                    <h2 className='mb-0'>Leave us a message!</h2>
                </div>
                <div className='row spl-mt-product'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 '>
                            <input type="text" class="form-control p-control" placeholder="Your Full Name *" aria-label="First name" />
                        </div>
                        <div className='col-lg-6 col-md-12 '>
                            <input type="email" class="form-control p-control" id="exampleFormControlInput1" placeholder="Your Email *" />
                        </div>
                        <div className='col-lg-6 col-md-12 '>
                            <input type="text" class="form-control p-control " placeholder="Your Address" aria-label="Address" />
                        </div>
                        <div className='col-lg-6 col-md-12 '>
                            <input type="number" class="form-control p-control " placeholder="Phone " aria-label="Phone  " />
                        </div>
                        <div className='col-12'>
                            <div class="mb-3">
                                <textarea class="form-control p-control" id="exampleFormControlTextarea1" rows="8" placeholder='Your Message'></textarea>
                            </div>
                        </div>
                        <Link to={'#'} className='send-us-now'>Send Us Now</Link>
                    </div>

                </div>
            </div>

            <div className='container p-main-80 pt-0'>
                <div className='row'>
                    <div className='col-lg-3 col-md-4 col-4'>
                        <div className="contat-icon-d">
                            <img src={locationicon} alt='locatio icon'></img>
                            <h3>Address</h3>
                            <p><Link to={'#'}>8901 Marmora Road, Glasgow, D04 89GR</Link></p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-4'>
                        <div className="contat-icon-d">
                            <img src={callicon} alt='locatio icon'></img>
                            <h3>Phone</h3>
                            <p><Link to={'tel:(426) 460 8668'}>(426) 460 8668</Link></p>
                            <p><Link to={'tel:(317) 799 7654'}>(317) 799 7654</Link></p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-4'>
                        <div className="contat-icon-d">
                            <img src={mailicon} alt='locatio icon'></img>
                            <h3>Emaill contact</h3>
                            <p><Link to={'mailto:kevin.price@example.com'}>kevin.price@example.com</Link></p>
                            <p><Link to={'mailto:ryanpatel@example.com'}>ryanpatel@example.com</Link></p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-4'>
                        <div className="contat-icon-d">
                            <img src={websiteicon} alt='locatio icon'></img>
                            <h3>Website</h3>
                            <p><Link to={'#'}>www.organive.store.com</Link></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs
