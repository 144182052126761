import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function HomeSlidertwo() {
  const settings = {
    // dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container p-main-80 pb-0">
      <Slider {...settings} className="d-flex ">
        <div className="container">
          <div className="organic-product">
            <h2>20% Most Save Product</h2>
            <h3>Gifts & <br />others</h3>
            <Link to={'/Shop'}>Shop Now <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
        <div className="container">
          <div className="organic-product organic-product-two">
            <h2>20% Most Save Product</h2>
            <h3>Best Quality <br />Women dresses</h3>
            <Link to={'/Shop'}>Shop Now <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
        <div className="container">
          <div className="organic-product organic-product-three">
            <h2>20% Most Save Product</h2>
            <h3>Best Quality <br />Beauty products</h3>
            <Link to={'/Shop'}>Shop Now <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
        <div className="container">
          <div className="organic-product organic-product-four">
            <h2>20% Most Save Product</h2>
            <h3>Organic <br />Sweets</h3>
            <Link to={'/Shop'}>Shop Now <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default HomeSlidertwo;
