import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';


const Footer = () => {
    return (
        <div>
            <div className='main-footer p-main-80'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12 col-12 mb-3'>
                            <div className='foot-first-sec'>
                                <Link to={'/Home'}><img src={logo} className='img-fluid' alt='Logo'></img></Link>
                                <p>We provide specialized winterization
                                    services to safeguard your pool during the
                                    off-season, and when spring arrives, we
                                    handle the thorough opening process.</p>
                                <div className='social-link'>
                                    <Link to={'#'}><i class="fa-brands fa-facebook-f"></i></Link>
                                    <Link to={'#'}><i class="fa-brands fa-twitter"></i></Link>
                                    <Link to={'#'}><i class="fa-brands fa-linkedin-in"></i></Link>
                                    <Link to={'#'}><i class="fa-brands fa-whatsapp"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-6 mb-3'>
                            <div className='foot-sec-sec'>
                                <h3>Categories</h3>
                                <p><Link to={'/Shop'}><i class="fa-solid fa-arrow-right"></i>Shop</Link></p>
                                <p><Link to={'/Cart'}><i class="fa-solid fa-arrow-right"></i>My Cart</Link></p>
                                <p><Link to={'/MyOrder'}><i class="fa-solid fa-arrow-right"></i>My Orders</Link></p>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-6 mb-3'>
                            <div className='foot-sec-sec'>
                                <h3>Quick Links</h3>
                                <p><Link to={'/About'}><i class="fa-solid fa-arrow-right"></i>About Us</Link></p>
                                <p><Link to={'#'}><i class="fa-solid fa-arrow-right"></i>Help & FAQs</Link></p>
                                <p><Link to={'/ContactUs'}><i class="fa-solid fa-arrow-right"></i>Contact Us</Link></p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12 mb-3'>
                            <div className='foot-sec-sec'>
                                <h3>Contact Us</h3>
                            </div>
                            <div className='location-call-mail'>
                                <div className='foot-location'>
                                    <i class="fa-solid fa-location-dot"></i>
                                    <p>8502 Preston Rd. Inglewood, Maine 98380</p>
                                </div>
                                <div className='foot-location'>
                                    <i class="fa-solid fa-phone"></i>
                                    <div>
                                        <p><Link to={'tel:+(163)-2654-3564'}>+(163)-2654-3564</Link></p>
                                        <p><Link to={'tel:+(163)-2654-5432'}>+(163)-2654-5432</Link></p>
                                    </div>
                                </div>
                                <div className='foot-location'>
                                    <i class="fa-solid fa-envelope"></i>
                                    <p><Link to={'mailto:help24/7@frutin.com'}>help24/7@frutin.com</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sec-footer'>
                <div className='container'>
                    <div className='copyright'>
                        <p>Copyright © 2023<Link to={'https://mechodal.com/'}> Mechodal Technology</Link>. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer