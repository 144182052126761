import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom'
import firstimghome from '../images/homefirstimg.svg'
import truckicon from '../images/truckicon.svg'
import cashicon from '../images/cashicon.svg'
import easyicon from '../images/easyicon.svg'
import productone from '../images/productone.svg'
import HomeSlider from './HomeSlider';
import HomeSlidertwo from './HomeSlidertwo';
import HomeOrganicSlider from './HomeOrganicSlider';
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa6";
// import homeslidertwofirst from "../images/homeslidertwo-one.svg";

const Home = () => {

    return (
        <div>
            {<Header />}
            <div className='homepage-bg py-main-120'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='home-first-sec-txt'>
                                <h2>URVI BAZZAR</h2>
                                <h3>Cosima Channel Sweets</h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
                                <Link to={'/Shop'}>SHOP NOW<i className="fa-solid fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-6 col-12'>
                            <img src={firstimghome} className='img-fluid' alt='first'></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row home-up-margin'>
                    <div className='col-lg-4 col-md-3 col-6'>
                        <div className='whitebox-home'>
                            <div className='whiteicon-box'>
                                <img src={truckicon} className='img-fluid' alt='first'></img>
                            </div>
                            <div className='whitebox-txt'>
                                <h2>Free Shipping</h2>
                                <p>Capped at $39 per order</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-3 col-6'>
                        <div className='whitebox-home'>
                            <div className='whiteicon-box'>
                                <img src={cashicon} className='img-fluid' alt='first'></img>
                            </div>
                            <div className='whitebox-txt'>
                                <h2>Card Payments</h2>
                                <p>12 Months Installments</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-3 col-6'>
                        <div className='whitebox-home'>
                            <div className='whiteicon-box'>
                                <img src={easyicon} className='img-fluid' alt='first'></img>
                            </div>
                            <div className='whitebox-txt'>
                                <h2>Easy Returns</h2>
                                <p>Shop With Confidence</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">

                {<HomeOrganicSlider />}

            </div>
            <div className='container p-main-80'>
                <div className='home-heading'>
                    <h2>Popular Products</h2>
                    <div className='tab-design-home'>
                        <ul className="nav nav-pills home-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">All</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="sweetsnack-tab" data-bs-toggle="tab" data-bs-target="#sweetsnack" type="button" role="tab" aria-controls="sweetsnack" aria-selected="false">Sweets & Snacks</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="womendress-tab" data-bs-toggle="tab" data-bs-target="#womendress" type="button" role="tab" aria-controls="womendress" aria-selected="false">Women dresses</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="beautyproduct-tab" data-bs-toggle="tab" data-bs-target="#beautyproduct" type="button" role="tab" aria-controls="beautyproduct" aria-selected="false">Beauty products</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="giftsother-tab" data-bs-toggle="tab" data-bs-target="#giftsother" type="button" role="tab" aria-controls="giftsother" aria-selected="false">Gifts & others</button>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div className='row'>

                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="sweetsnack" role="tabpanel" aria-labelledby="sweetsnack-tab">
                            <div className='row'>

                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="womendress" role="tabpanel" aria-labelledby="womendress-tab">
                            <div className='row'>

                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="beautyproduct" role="tabpanel" aria-labelledby="beautyproduct-tab">
                            <div className='row'>

                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="giftsother" role="tabpanel" aria-labelledby="giftsother-tab">
                            <div className='row'>

                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position grn-bg">
                                                    <h4>Sale</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-4 col-6 mb-3'>
                                    <Link to={'/AboutItem'}>
                                        <div className="product-box-bg ">
                                            <div className='product-img'>
                                                <img src={productone} className='img-fluid' alt='first' />
                                                <div className="div-hide">
                                                    <div className="cart-wishlist">
                                                        <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                        <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                    </div>
                                                </div>
                                                <div className="product-txt-position ">
                                                    <h4>HOT</h4>
                                                </div>
                                            </div>
                                            <h3>Pellentesque aliquet</h3>
                                            <h5>68.00 $<span>80.50 $</span></h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className='p-main-80 homepage-bg '>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12  menuflx'>
                            <div className='home-slider-heading d-flex '>
                                <h2>Our Popular Products</h2>
                            </div>
                            <div className='second-slider-tab-design-to'>
                                <ul class="nav nav-pills slider-nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-home-all-tab" data-bs-toggle="pill" data-bs-target="#pills-home-all" type="button" role="tab" aria-controls="pills-home-all" aria-selected="true">All</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-profile-sweets-tab" data-bs-toggle="pill" data-bs-target="#pills-profile-sweets" type="button" role="tab" aria-controls="pills-profile-sweets" aria-selected="false">Sweets&Snacks</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-contact-women-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-women" type="button" role="tab" aria-controls="pills-contact-women" aria-selected="false">Women dresses</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-profile-beauty-tab" data-bs-toggle="pill" data-bs-target="#pills-profile-beauty" type="button" role="tab" aria-controls="pills-profile-beauty" aria-selected="false">Beauty products</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-contact-gifts-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-gifts" type="button" role="tab" aria-controls="pills-contact-gifts" aria-selected="false">Gifts & others</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-6 col-12'>
                            <div className='home-slider-heading-tabs'>

                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home-all" role="tabpanel" aria-labelledby="pills-home-all-tab">
                                        <HomeSlider />
                                    </div>
                                    {/* <div class="tab-pane fade" id="pills-profile-sweets" role="tabpanel" aria-labelledby="pills-profile-sweets-tab">
                                        <HomeSlider />
                                    </div>
                                    <div class="tab-pane fade" id="pills-contact-women" role="tabpanel" aria-labelledby="pills-contact-women-tab">
                                        <HomeSlider />
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile-beauty" role="tabpanel" aria-labelledby="pills-profile-beauty-tab">
                                        <HomeSlider />
                                    </div>
                                    <div class="tab-pane fade " id="pills-contact-gifts" role="tabpanel" aria-labelledby="pills-contact-gifts-tab">
                                        <HomeSlider />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='p-main-80'>
                <div className="container">
                    <div className="home-heading">
                        <h2>Our Customer Feedback</h2>
                    </div>
                    <HomeSlidertwo />
                </div>
            </section>

            <div className='container p-main-80 pt-0'>
                <div className='subcribe-bg'>
                    <div className='row spl-cen'>
                        <div className='col-lg-4 col-md-6 col-6'>
                            <div className='subcribe-txt'>
                                <h2>Sign Up to Get Updates & News About Us.</h2>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-6'>
                            <div className='subcribe-txt'>
                                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                                <Link to={'#'}>Subscribe</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {<Footer />}
        </div>
    )
}

export default Home
