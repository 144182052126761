import React from 'react'
import Header from './Header'
import Footer from './Footer';
import jalebi from '../images/jalebi.svg'
import jalebitwo from '../images/jalebi-two.svg'
import { Link } from 'react-router-dom'

const MyOrder = () => {
    return (
        <div>
            <Header />
            <div className='aboutfirst mt-103'>
                <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>My Orders</p>
            </div>
            <div className='container p-main-80'>
                <div className='row'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                <th scope="col">Product</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Total</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='table-data'>
                                <td>
                                    <div className="table-pro-name">
                                        <img src={jalebi} alt="" />
                                        <div>
                                            <p>#24586</p>
                                            <p>Cheery</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>20 August 2018</p></div>
                                </td>
                                <td>
                                    <div className="complete-order-tbl">
                                        <p><i class="fa-solid fa-circle"></i> completed</p>
                                        <h6>( Order Deliver On <span>22th March</span> <span>06:00 AM</span> )</h6>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>$ 18.00 <span>( 1 item )</span></p></div>
                                </td>
                                <td>
                                    <div className="tbl-sec-txt">
                                        <Link to={'/OrderDetail'}>VIEW DETAILS</Link>
                                    </div>
                                </td>
                            </tr>
                            <tr className='table-data'>
                                <td>
                                    <div className="table-pro-name">
                                        <img src={jalebi} alt="" />
                                        <div>
                                            <p>#24586</p>
                                            <p>Cheery</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>20 August 2018</p></div>
                                </td>
                                <td>
                                    <div className="processing-order-tbl">
                                        <p><i class="fa-solid fa-circle"></i> Processing</p>
                                        <h6>( Order Deliver On <span>22th March</span> <span>06:00 AM</span> )</h6>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>$ 18.00 <span>( 1 item )</span></p></div>
                                </td>
                                <td>
                                    <div className="tbl-sec-txt">
                                        <Link to={'/OrderDetail'}>VIEW DETAILS</Link>
                                    </div>
                                </td>
                            </tr>
                            <tr className='table-data'>
                                <td>
                                    <div className="table-pro-name">
                                        <img src={jalebi} alt="" />
                                        <div>
                                            <p>#24586</p>
                                            <p>Cheery</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>20 August 2018</p></div>
                                </td>
                                <td>
                                    <div className="cancell-order-tbl">
                                        <p><i class="fa-solid fa-circle"></i> Order Cancelled</p>
                                        <Link to={'#'}>SEE WHY ?</Link>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>$ 18.00 <span>( 1 item )</span></p></div>
                                </td>
                                <td>
                                    <div className="tbl-sec-txt">
                                        <Link to={'/OrderDetail'}>VIEW DETAILS</Link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MyOrder
