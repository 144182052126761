import React, { useState } from 'react';
import Header from './Header'
import Footer from './Footer';
import jalebi from '../images/jalebi.svg'
import jalebitwo from '../images/jalebi-two.svg'
import { Link } from 'react-router-dom'

const Cart = () => {
    const [countNum, setCountNum] = useState(0);

    const handleMinusClick = () => {
        setCountNum((prevCount) => prevCount - 1);
    };

    const handlePlusClick = () => {
        setCountNum((prevCount) => prevCount + 1);
    };
    return (
        <div>
            <Header />
            <div className='aboutfirst mt-103'>
                <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>Cart</p>
            </div>
            <div className='container p-main-80'>
                <div className='row'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                <th scope="col">PRODUCT</th>
                                <th scope="col">PRICE</th>
                                <th scope="col">QUANTITY</th>
                                <th scope="col">TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='table-data'>
                                <td>
                                    <div className="table-pro-name">
                                        <img src={jalebi} alt="" />
                                        <p>Cheery</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>$ 18.00</p></div>
                                </td>
                                <td>
                                    <div className="framee">
                                        <div className="containerr">
                                            <div className="button-plus-minus">
                                                <button className='border-0 bg-transparent' id="minus-btn" onClick={handleMinusClick}>-</button>
                                            </div>
                                            <div className="number">
                                                <p id="count">{countNum}</p>
                                            </div>
                                            <div className="button-plus-minus">
                                                <button className='border-0 bg-transparent' id="plus-btn" onClick={handlePlusClick}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-shop-now">
                                        <div className="tbl-pro-price"><p>$ 18.00</p></div>
                                        <div className="cancle-tbl">
                                            <Link to={'#'} className="cancle"><i class="fa-solid fa-xmark"></i></Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className='table-data'>
                                <td>
                                    <div className="table-pro-name">
                                        <img src={jalebitwo} alt="" />
                                        <p>Asparagus</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>$ 18.00</p></div>
                                </td>
                                <td>
                                    <div className="framee">
                                        <div className="containerr">
                                            <div className="button-plus-minus">
                                                <button className='border-0 bg-transparent' id="minus-btn" onClick={handleMinusClick}>-</button>
                                            </div>
                                            <div className="number">
                                                <p id="count">{countNum}</p>
                                            </div>
                                            <div className="button-plus-minus">
                                                <button className='border-0 bg-transparent' id="plus-btn" onClick={handlePlusClick}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-shop-now">
                                        <div className="tbl-pro-price"><p>$ 18.00</p></div>
                                        <div className="cancle-tbl">
                                            <Link to={'#'} className="cancle"><i class="fa-solid fa-xmark"></i></Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="three-btn">
                        <div className='two-btn-tbl'>
                            <div className="input-group search-shop w-auto me-3">
                                <input type="text" className="form-control px-3" placeholder="Coupon Code" aria-label="Search" aria-describedby="button-addon2" />
                            </div>
                            <div className="tbl-sec-txt">
                                <Link to={'#'}>apply coupon</Link>
                            </div>
                        </div>
                        <div className="tbl-sec-txt">
                            <Link to={'#'}>update CART</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container p-main-80 pt-0">
                <div className="cart-totals">
                    <h3>CART TOTALS</h3>
                    <div className="total-subtotal">
                        <div className="subtotal">
                            <p>Subtotal</p>
                            <h4>48$</h4>
                        </div>
                        <div className="subtotal">
                            <p>Total</p>
                            <h4 className='text-urvi'>48$</h4>
                        </div>
                    </div>
                    <div className="tbl-sec-txt">
                        <Link to={'/PaymentOption'}>proceed to checkout</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cart
