import React, { useState } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Slider } from "primereact/slider";

export default function RangeDemo() {
    const [value, setValue] = useState([20, 80]);

    return (
        <div className="card flex justify-content-center">
            <Slider value={value} onChange={(e) => setValue(e.value)} className="w-14rem" range />
        </div>
    )
}