import React from 'react'
import Header from './Header';
import Footer from './Footer';
import aboutcustomer from '../images/aboutcustomer.png';
import nourishabout from '../images/nourishabout.svg';
import icon1 from '../images/100-organic.svg';
import icon2 from '../images/fresh-product.svg';
import icon3 from '../images/bio-daynamic-food.svg';
import icon4 from '../images/Secured-Payment.svg';
import iconproductone from '../images/icon-product-one.svg';
import iconproducttwo from '../images/icon-product-two.svg';
import iconproductthree from '../images/icon-product-three.svg';
import iconproductfour from '../images/icon-product-four.svg';
import HomeSlidertwo from './HomeSlidertwo';
// import Slider from './Slider';
import { Link } from 'react-router-dom'

function About() {
  return (
    <div>
        {<Header/>}
        <div className='aboutfirst mt-103'>
           <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>About Us</p>
        </div>
        <div className='container p-main-80'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 my-2'>
                    <div className='aboutcustomer'>
                        <img src={aboutcustomer} className='img-fluid'/>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 my-2'>
                    <div className='aboutcustomer-text'>
                        <h3>About Our Company</h3>
                        <h2>Eating Right Start With Best Quality Sweets </h2>
                        <p>Organic foods are produced through a farming system that avoids the
                        use of synthetic pesticides, herbicides, genetically modified organism
                        (GMOs), and artificial fertilizers. Instead, organic farmers rely on natural
                        methods like crop rotation. composting, and biological pest control.</p>
                        <hr/>
                        <Link to={'#'} className='regi-btn'>Discover More</Link>
                    </div>
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 my-2'>
                    <div className='aboutcustomer-text'>
                        <h3>Why Choose Us</h3>
                        <h2>Nourish Your Body with Pure Organic Goodness! </h2>
                        <p>Governments have regulations in place to ensure that products labeled as
                        organic meet specific standards. Regular inspections and audits are conducted
                        to maintain the integrity of the organic label.</p>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 my-2'>
                    <div className='aboutcustomer'>
                        <img src={nourishabout} className='img-fluid'/>
                    </div>
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='bg-product'>
                <div className='row'>
                    <div className='col-6'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='organic'>
                                    <div className='inside-corner'>
                                        <img src={icon1} className='img-fluid'/>
                                    </div>
                                    <div className='organic-text'>
                                        <h2>100% Organic</h2>
                                        <p>Our products are certified by reputable organic.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='organic'>
                                    <div className='inside-corner'>
                                        <img src={icon2} className='img-fluid'/>
                                    </div>
                                    <div className='organic-text'>
                                        <h2>Fresh Products</h2>
                                        <p>Our products are certified by reputable organic.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='organic'>
                                    <div className='inside-corner'>
                                        <img src={icon3} className='img-fluid'/>
                                    </div>
                                    <div className='organic-text'>
                                        <h2>Biodynamic Food</h2>
                                        <p>Our products are certified by reputable organic.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='organic'>
                                    <div className='inside-corner'>
                                        <img src={icon4} className='img-fluid'/>
                                    </div>
                                    <div className='organic-text'>
                                        <h2>Secured Payment</h2>
                                        <p>Our products are certified by reputable organic.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='ourproductbg'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-12 my-2 side-border'>
                        <div className='our-product-d'>
                            <div className='icon-product'>
                                    <img src={iconproductone} className='img-fluid'/>
                            </div>
                            <div className='no-product'>
                                <h4>15663+</h4>
                                <p>Our Total Products</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 my-2 side-border'>
                        <div className='our-product-d'>
                            <div className='icon-product'>
                                    <img src={iconproducttwo} className='img-fluid'/>
                            </div>
                            <div className='no-product'>
                                <h4>356+</h4>
                                <p>Team Members</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 my-2 side-border'>
                        <div className='our-product-d'>
                            <div className='icon-product'>
                                    <img src={iconproductthree} className='img-fluid'/>
                            </div>
                            <div className='no-product'>
                                <h4>2365+</h4>
                                <p>Satisfied Customers</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 my-2'>
                        <div className='our-product-d'>
                            <div className='icon-product'>
                                    <img src={iconproductfour} className='img-fluid'/>
                            </div>
                            <div className='no-product'>
                                <h4>156+</h4>
                                <p>Awards Winning</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className='p-main-80 pb-0'>
            <div className="container">
                <div className="home-heading">
                    <h2>Our Customer Feedback</h2>
                </div>
                <HomeSlidertwo/>
            </div>
        </section>

        <div className='container p-main-80'>
            <div className='subcribe-bg'>
                <div className='row spl-cen'>
                    <div className='col-lg-4 col-md-6 col-6'>
                        <div className='subcribe-txt'>
                            <h2>Sign Up to Get Updates & News About Us.</h2>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <div className='subcribe-txt'>
                          <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address"/>
                          <Link to={'#'}>Subscribe</Link>
                        </div>
                    </div>        
                </div>
            </div>
        </div>
        {<Footer/>}
    </div>
  )
}

export default About
