import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import PriceFilter from "./PriceFilter";
import productone from '../images/productone.svg';
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa6";
// import Slider from './Slider';
import { Link } from "react-router-dom";

function Shop() {
    return (
        <div>
            {<Header />}
            <div className="aboutfirst mt-103">
                <p>
                    {" "}
                    <Link to={"/Home"}>Home</Link>
                    <span className="px-3 text-dark">/</span>Shop
                </p>
            </div>
            <div className="container p-main-80">
                <div className="search-sort">
                    <div className="input-group search-shop w-auto">
                        <input type="text" className="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2"><span className="fa fa-search form-control-feedback"></span></button>
                    </div>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto mt-0 sort-by">
                            <label for="inputPassword6" className="col-form-label">
                                Sort by :
                            </label>
                        </div>
                        <div className="col-auto mt-0 sort-by">
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Open this select</option>
                                <option value="1">Best seller</option>
                                <option value="2">Best seller</option>
                                <option value="3">Best seller</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-12 my-2">
                        <div className="product-category-all">
                            <h5>PRODUCT CATEGORIES</h5>
                            <div className="pro-check-box">
                                <div className="total-all-pro">
                                    <div>
                                        <input type="checkbox" className="d-none" id="cbox1" value="third_checkbox" checked /> <label for="cbox1"> All </label>
                                    </div>
                                    <p>6</p>
                                </div>
                                <div className="total-all-pro">
                                    <div>
                                        <input type="checkbox" className="d-none" id="cbox2" value="third_checkbox" /> <label for="cbox2"> Sweets & Snacks </label>
                                    </div>
                                    <p>6</p>
                                </div>
                                <div className="total-all-pro">
                                    <div>
                                        <input type="checkbox" className="d-none" id="cbox3" value="third_checkbox" /> <label for="cbox3"> Snacks </label>
                                    </div>
                                    <p>6</p>
                                </div>
                                <div className="total-all-pro">
                                    <div>
                                        <input type="checkbox" className="d-none" id="cbox4" value="third_checkbox" /> <label for="cbox4"> Pickles </label>
                                    </div>
                                    <p>6</p>
                                </div>
                                <div className="total-all-pro">
                                    <div>
                                        <input type="checkbox" className="d-none" id="cbox5" value="third_checkbox" /> <label for="cbox5"> Spices </label>
                                    </div>
                                    <p>6</p>
                                </div>
                            </div>
                        </div>
                        <div className="product-category-all">
                            <h5>Filter by price</h5>
                            <div className="pro-check-box">
                                {<PriceFilter />}
                                <div className="filter-price">
                                    <button className="btn btn-filter">Filter</button>
                                    <p>Price : <span>$0</span> - <span>$50</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="product-category-all">
                            <h5>top rate</h5>
                            <div className="pro-check-box">
                                <div className="top-rate-pro">
                                    <img src={productone} alt="" className="img-fluid" />
                                    <div className="top-pro-price">
                                        <p>Vitamin C face wash</p>
                                        <h4>21.00 $</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="pro-check-box">
                                <div className="top-rate-pro">
                                    <img src={productone} alt="" className="img-fluid" />
                                    <div className="top-pro-price">
                                        <p>Vitamin C face wash</p>
                                        <h4>21.00 $</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="pro-check-box">
                                <div className="top-rate-pro">
                                    <img src={productone} alt="" className="img-fluid" />
                                    <div className="top-pro-price">
                                        <p>Vitamin C face wash</p>
                                        <h4>21.00 $</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="pro-check-box">
                                <div className="top-rate-pro">
                                    <img src={productone} alt="" className="img-fluid" />
                                    <div className="top-pro-price">
                                        <p>Vitamin C face wash</p>
                                        <h4>21.00 $</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="pro-check-box">
                                <div className="top-rate-pro">
                                    <img src={productone} alt="" className="img-fluid" />
                                    <div className="top-pro-price">
                                        <p>Vitamin C face wash</p>
                                        <h4>21.00 $</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-category-all">
                            <h5>product tags</h5>
                            <div className="pro-check-box">
                                <div className="pro-teg">
                                    <p>Plant</p>
                                    <p>Floor</p>
                                    <p>Indoor</p>
                                    <p>Green</p>
                                    <p>Healthy</p>
                                    <p>Cactus</p>
                                    <p>House plant</p>
                                    <p>Office tree</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-8 col-12 my-2">
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position ">
                                                <h4>HOT</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position grn-bg">
                                                <h4>Sale</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position ">
                                                <h4>HOT</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position ">
                                                <h4>HOT</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position grn-bg">
                                                <h4>Sale</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position ">
                                                <h4>HOT</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position ">
                                                <h4>HOT</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position grn-bg">
                                                <h4>Sale</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-6 mb-3'>
                                <Link to={'/AboutItem'}>
                                    <div className="product-box-bg ">
                                        <div className='product-img'>
                                            <img src={productone} className='img-fluid' alt='first' />
                                            <div className="div-hide">
                                                <div className="cart-wishlist">
                                                    <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                                </div>
                                            </div>
                                            <div className="product-txt-position ">
                                                <h4>HOT</h4>
                                            </div>
                                        </div>
                                        <h3>Pellentesque aliquet</h3>
                                        <h5>68.00 $<span>80.50 $</span></h5>
                                    </div>
                                </Link>
                            </div>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <Link to={'#'}><i class="fa-solid fa-chevron-left"></i></Link>
                                    </li>
                                    <li className="page-item active"><Link to={'#'}>1</Link></li>
                                    <li className="page-item"><Link to={'#'}>2</Link></li>
                                    <li className="page-item"><Link to={'#'}>...</Link></li>
                                    <li className="page-item">
                                        <Link to={'#'}><i class="fa-solid fa-chevron-right"></i></Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer />}
        </div>
    );
}

export default Shop;
