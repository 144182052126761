import React from 'react'
import creat from '../images/create-account.svg'; // with import
import { Link } from 'react-router-dom';


const LoginPage = () => {
  return (
    <div>
        <div className='bg-create'>
            <img src={creat}/>
        </div>
        <div className='container pb-5'>
            <div className='row spl-center top-form'>
                <div className='col-lg-8 col-md-8 col-12'>
                        <div className='white-bg'>
                            <div className='createtxt'>
                                <h2>Login From Here</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
                            </div>
                            <div className='col-12'>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label frn-label">Email address <span>**</span></label>
                                    <input type="email" className="form-control frn-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="mb-3">
                                    <label for="exampleInputPassword1" className="form-label frn-label">Password <span>**</span></label>
                                    <input type="password" className="form-control frn-control" id="exampleInputPassword1" placeholder='Enter password'/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-3">
                                <div className="form-check check-text">
                                    <input className="form-check-input" type="checkbox" id="gridCheck" />
                                    <label className="form-check-label" for="gridCheck">Remember me!</label>
                                </div>
                                <div className='forgot-pass'>
                                    <Link to={'#'}>Forgget your password?</Link>
                                </div>
                            </div>
                            <div className='col-12'>
                                <Link to={'/Home'} className='regi-btn'> Login now</Link>
                            </div>
                            <div className='col-12 mt-3 mb-3'>
                                <div className='or-txt'>
                                        <hr/> 
                                        <h3>or</h3>
                                        <hr/>
                                </div>
                            </div>
                            <div className='col-12'>
                                <Link to={'/CreateAccount'} className='regi-btn-to'>Create account</Link>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LoginPage