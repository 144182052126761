import React, { useState } from 'react';
import Header from './Header'
import Footer from './Footer';
import jalebiabout from '../images/jalebiabout.svg'
import jalebi from '../images/jalebi.svg'
import jalebitwo from '../images/jalebi-two.svg'
import { Link } from 'react-router-dom'
import { MdOutlineZoomOutMap } from "react-icons/md";
import productone from '../images/productone.svg'
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa6";
import { TbSquareRoundedCheck } from "react-icons/tb";

const Cart = () => {
    const [countNum, setCountNum] = useState(0);

    const [Image, setImage] = useState(jalebiabout)

    const handleMinusClick = () => {
        setCountNum((prevCount) => prevCount - 1);
    };

    const handlePlusClick = () => {
        setCountNum((prevCount) => prevCount + 1);
    };
    return (
        <div>
            <Header />
            <div className='aboutfirst mt-103'>
                <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>About Item</p>
            </div>

            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <img src={Image} alt="" className='img-fluid w-100' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container p-main-80'>
                <div className='row'>
                    <div className="col-md-6 col-12 mb-3">
                        <div className="about-img">
                            <Link to={'#'} data-bs-toggle="modal" data-bs-target="#exampleModal"><MdOutlineZoomOutMap /></Link>
                            <img src={Image} alt="" className='img-fluid' />
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className='img-about-three'>
                                    <img src={jalebiabout} onClick={() => setImage(jalebiabout)} alt="" className='img-fluid w-100' />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='img-about-three'>
                                    <img src={jalebi} onClick={() => setImage(jalebi)} alt="" className='img-fluid w-100' />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='img-about-three'>
                                    <img src={jalebitwo} alt="" className='img-fluid w-100' onClick={() => setImage(jalebitwo)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                        <div className="about-product">
                            <h2>Avakaya Pachadi (Mango Pickle)</h2>
                            <div className="rating-review">
                                <div className="star-five">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <p>(05 Reviews)</p>
                            </div>
                            <h3>$250.99</h3>
                            <p>On the other hand, we denounce with righteous
                                indignation and dislike men who are so beguiled
                                and demoralized by the charms</p>
                            <p>But I must explain to you how all this mistaken
                                idea of denouncing pleasure. On the other hand, we denounce with righteous
                                indignation and dislike men who are so beguiled
                                and demoralized by the charms</p>
                            <div className="add-to-cart">
                                <div className="framee">
                                    <div className="containerr">
                                        <div className="button-plus-minus">
                                            <button className='border-0 bg-transparent' id="minus-btn" onClick={handleMinusClick}>-</button>
                                        </div>
                                        <div className="number">
                                            <p id="count" className='mb-0'>{countNum}</p>
                                        </div>
                                        <div className="button-plus-minus">
                                            <button className='border-0 bg-transparent' id="plus-btn" onClick={handlePlusClick}>+</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tbl-sec-txt">
                                    <Link to={'/Cart'}>Add To CART <i class="fa-solid fa-cart-plus ms-2"></i></Link>
                                </div>
                            </div>
                            <div className="click-wishlist">
                                <Link to={'/Wishlist'}>
                                    <i class="fa-regular fa-heart"></i>
                                </Link>
                            </div>
                            <div className="category-about">
                                <h4>Categories :<span>Sweets</span></h4>
                            </div>
                            <div className="share-about">
                                <h4>Share Now :</h4>
                                <div className="share-about-social">
                                    <Link to={'#'}><i class="fa-brands fa-facebook-f"></i></Link>
                                    <Link to={'#'}><i class="fa-brands fa-instagram"></i></Link>
                                    <Link to={'#'}><i class="fa-brands fa-twitter"></i></Link>
                                    <Link to={'#'}><i class="fa-brands fa-google"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container p-main-80 pt-0">
                <ul class="nav nav-pills mb-3 home-pills" id="pills-tab" role="tablist">
                    <li class="nav-item me-md-4 me-3" role="presentation">
                        <button class="nav-link active px-0" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Description</button>
                    </li>
                    <li class="nav-item me-md-4 me-3" role="presentation">
                        <button class="nav-link px-0" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Additional information</button>
                    </li>
                    <li class="nav-item me-md-4 me-3" role="presentation">
                        <button class="nav-link px-0" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Reviews (2)</button>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="dis-about">
                            <p>Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae
                                ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia consequuntur magni doloreos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                                qui dolorem ipsum quia dolor siconsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
                                magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi
                                ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit</p>
                            <h6><span><TbSquareRoundedCheck /></span> Can Data Visualization Improve The Mobile Web Experience?</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Smashing Podcast Episode 16 With Ben Frain: How Can Optimize Workspace</h6>
                            <h6><span><TbSquareRoundedCheck /></span> How To Make Performance Visible With GitLab CI And Hoodoo Of GitLab</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Learning Resources In Challenging Times: Online Work And Events</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Can Data Visualization Improve The Mobile Web Experience?</h6>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="dis-about">
                            <p>Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae
                                ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia consequuntur magni doloreos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                                qui dolorem ipsum quia dolor siconsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
                                magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi
                                ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit</p>
                            <h6><span><TbSquareRoundedCheck /></span> Can Data Visualization Improve The Mobile Web Experience?</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Smashing Podcast Episode 16 With Ben Frain: How Can Optimize Workspace</h6>
                            <h6><span><TbSquareRoundedCheck /></span> How To Make Performance Visible With GitLab CI And Hoodoo Of GitLab</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Learning Resources In Challenging Times: Online Work And Events</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Can Data Visualization Improve The Mobile Web Experience?</h6>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="dis-about">
                            <p>Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae
                                ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia consequuntur magni doloreos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                                qui dolorem ipsum quia dolor siconsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
                                magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi
                                ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit</p>
                            <h6><span><TbSquareRoundedCheck /></span> Can Data Visualization Improve The Mobile Web Experience?</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Smashing Podcast Episode 16 With Ben Frain: How Can Optimize Workspace</h6>
                            <h6><span><TbSquareRoundedCheck /></span> How To Make Performance Visible With GitLab CI And Hoodoo Of GitLab</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Learning Resources In Challenging Times: Online Work And Events</h6>
                            <h6><span><TbSquareRoundedCheck /></span> Can Data Visualization Improve The Mobile Web Experience?</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container p-main-80 pt-0'>
                <div className='home-heading'>
                    <h6>Featured Product</h6>
                    <h2>You May Like Also</h2>
                    <div className='row'>

                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                            <Link to={'/AboutItem'}>
                                <div className="product-box-bg ">
                                    <div className='product-img'>
                                        <img src={productone} className='img-fluid' alt='first' />
                                        <div className="div-hide">
                                            <div className="cart-wishlist">
                                                <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                            </div>
                                        </div>
                                        <div className="product-txt-position ">
                                            <h4>HOT</h4>
                                        </div>
                                    </div>
                                    <h3>Pellentesque aliquet</h3>
                                    <h5>68.00 $<span>80.50 $</span></h5>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                            <Link to={'/AboutItem'}>
                                <div className="product-box-bg ">
                                    <div className='product-img'>
                                        <img src={productone} className='img-fluid' alt='first' />
                                        <div className="div-hide">
                                            <div className="cart-wishlist">
                                                <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                            </div>
                                        </div>
                                        <div className="product-txt-position grn-bg">
                                            <h4>Sale</h4>
                                        </div>
                                    </div>
                                    <h3>Pellentesque aliquet</h3>
                                    <h5>68.00 $<span>80.50 $</span></h5>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                            <Link to={'/AboutItem'}>
                                <div className="product-box-bg ">
                                    <div className='product-img'>
                                        <img src={productone} className='img-fluid' alt='first' />
                                        <div className="div-hide">
                                            <div className="cart-wishlist">
                                                <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                            </div>
                                        </div>
                                        <div className="product-txt-position ">
                                            <h4>HOT</h4>
                                        </div>
                                    </div>
                                    <h3>Pellentesque aliquet</h3>
                                    <h5>68.00 $<span>80.50 $</span></h5>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                            <Link to={'/AboutItem'}>
                                <div className="product-box-bg ">
                                    <div className='product-img'>
                                        <img src={productone} className='img-fluid' alt='first' />
                                        <div className="div-hide">
                                            <div className="cart-wishlist">
                                                <Link to={'/Cart'}><PiShoppingCartSimpleBold /></Link>
                                                <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                            </div>
                                        </div>
                                        <div className="product-txt-position ">
                                            <h4>HOT</h4>
                                        </div>
                                    </div>
                                    <h3>Pellentesque aliquet</h3>
                                    <h5>68.00 $<span>80.50 $</span></h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cart
