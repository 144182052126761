import React from 'react'
import Header from './Header'
import Footer from './Footer';
import jalebi from '../images/jalebi.svg'
import jalebitwo from '../images/jalebi-two.svg'
import { Link } from 'react-router-dom'

const Wishlist = () => {
    return (
        <div>
            <Header />
            <div className='aboutfirst mt-103'>
                <p> <Link to={'/Home'}>Home</Link><span className='px-3 text-dark'>/</span>Wishlist</p>
            </div>
            <div className='container p-main-80'>
                <div className='row'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                <th scope="col">Product Name</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Stock Status</th>
                                <th scope="col">Add to cart</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='table-data'>
                                <td>
                                    <div className="table-pro-name">
                                        <img src={jalebi} alt="" />
                                        <p>Cheery</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>$ 18.00</p></div>
                                </td>
                                <td>
                                    <div className="tbl-in-stock">
                                        <p><span> <i class="fa-solid fa-circle-check"></i></span>In Stock</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-shop-now">
                                        <div className="tbl-sec-txt">
                                            <Link to={'/Shop'}>SHOP NOW<i class="fa-solid fa-angles-right"></i></Link>
                                        </div>
                                        <div className="cancle-tbl">
                                            <Link to={'#'} className="cancle"><i class="fa-solid fa-xmark"></i></Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className='table-data'>
                                <td>
                                    <div className="table-pro-name">
                                        <img src={jalebitwo} alt="" />
                                        <p>Asparagus</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-pro-price"><p>$ 12.00</p></div>
                                </td>
                                <td>
                                    <div className="tbl-in-stock tbl-out-stock">
                                        <p><span> <i class="fa-solid fa-circle-xmark"></i></span>Out Off Stock</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbl-shop-now">
                                        <div className="tbl-first-sec-txt">
                                            <Link to={'/Shop'} className='me-0'>SHOP NOW<i class="fa-solid fa-angles-right"></i></Link>
                                        </div>
                                        <div className="cancle-tbl">
                                            <Link to={'#'} className="cancle"><i class="fa-solid fa-xmark"></i></Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="three-btn">
                        <div className='two-btn-tbl'>
                            <div className="tbl-first-sec-txt">
                                <Link to={'/Shop'}>Clear wishlist<i class="fa-solid fa-angles-right"></i></Link>
                            </div>
                            <div className="tbl-first-sec-txt">
                                <Link to={'/Shop'}>Update wishlist<i class="fa-solid fa-angles-right"></i></Link>
                            </div>
                        </div>
                        <div className="tbl-sec-txt">
                            <Link to={'/Shop'}>Continue shopping<i class="fa-solid fa-angles-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Wishlist
